// import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSetMapLocation } from '../common/redux/hooks'
import { useDoModal } from '../user/redux/doModal'
import { path } from 'ramda'
import { userCanSocialPost, getMapLocationLabel } from '../../common/ambient'
import { LocationAutocomplete } from '../common/'
import classNames from 'classnames'
import bindAllActions from '../../common/bindAllActions'
import {
  MyTimeline
} from './'

const DefaultPage = ({ device, user, social }) => {
  const { mapLocation, setMapLocation } = useSetMapLocation()
  const { doModal } = useDoModal()
  const { allPosts } = social
  const [scrolled, setScrolled] = useState(false)
  const [tab, setTab] = useState('all')
  useEffect(() => {
    const hash = path(['location', 'hash'], window)
    if (!hash || scrolled) return

    const el = document.getElementById(hash.replace('#', ''))
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth'
      })
      setScrolled(true)
    }
  }, [allPosts, setScrolled, scrolled])
  return (
    <div className={classNames('social-default-page', tab)}>
      <LocationAutocomplete
        placeholder={getMapLocationLabel(mapLocation)}
        onPlaceSelected={setMapLocation}
      />
      <MyTimeline
        onTabChange={setTab}
        geo={path(['geo'], mapLocation)}
      />
      {userCanSocialPost(user, device) &&
        <a
          className='create-post-btn'
          onClick={() => {
            doModal({
              type: 'create-post'
            })
          }}
        />}
    </div>
  )
}

export default bindAllActions(DefaultPage)

DefaultPage.displayName = 'DefaultPage'
