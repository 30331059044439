import React, { PureComponent } from 'react'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { InView } from 'react-intersection-observer'
import bindAllActions from '../../common/bindAllActions'
import PropTypes from 'prop-types'
import { map, sort, pluck, path, last, pipe, concat, uniqBy } from 'ramda'
import { DevicePopup } from '../device'
import {
  Loader
} from '../../components'
import { deviceIsMine, dateVal, getDeviceSharePath, isAdmin, isDev, latLonDistance, pathsChanged, isLoggedIn } from '../../common/ambient'
import * as turf from '@turf/helpers'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'

const KM = 161

class MyTimeline extends PureComponent {
  static propTypes = {
    socialActions: PropTypes.object,
    currentDevice: PropTypes.object,
    postId: PropTypes.string,
    postIds: PropTypes.array,
    geo: PropTypes.object, // geo json
    onTabChange: PropTypes.func
  }

  state = {
    fetchedPage: 0,
    page: 1,
    tab: 'all',
    isAtTop: false
  }

  componentDidMount () {
    this._mounted = true
    this._fetchPage()
    this._fetchBoosted()
    window.addEventListener('scroll', this.handleScroll)
  }

  componentDidUpdate (prevProps, prevState) {
    this._fetchBoosted()
    // new device or postId or geo or logging in
    if (pathsChanged(this.props, prevProps, [['currentDevice', '_id'], ['postId'], ['geo'], ['user', 'userChecked']]) ||
    pathsChanged(this.state, prevState, [['tab']]) // tab changed
    ) {
      this._setState({
        page: 1,
        fetchedPage: 0
      }, this._fetchPage.bind(this))
    }
    if (this.props.onTabChange && prevState.tab !== this.state.tab) {
      this.props.onTabChange(this.state.tab)
    }
  }

  componentWillUnmount () {
    this._mounted = false
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (this._mounted) {
      const element = document.querySelector('.social-my-timeline')
      if (element) {
        const rect = element.getBoundingClientRect()
        const isAtTop = rect.top <= 111
        if (this.state.isAtTop !== isAtTop) {
          this.setState({ isAtTop })
        }
      }
    }
  }

  _setState (what, cb) {
    if (this._mounted) {
      this.setState(what, cb)
    }
  }

  _fetchBoosted () {
    const { social, socialActions } = this.props
    const { fetchedBoosted } = social
    if (fetchedBoosted) return
    socialActions.fetchPosts({
      dataKey: 'boosted',
      $limit: 20,
      status: 'boosted',
      $sort: {
        createdAt: -1
      }
    })
  }

  _showLocationBasedPosts () {
    const { currentDevice } = this.props
    // logged in user
    return this.state.tab === 'location' &&
      ((currentDevice && path(['info', 'coords', 'geo'], currentDevice)) || this._isSocialPage())
  }

  _isUserDashboard () {
    return !this._isSocialPage()
  }

  _fetchPage () {
    if (!this.props.user.userChecked) return
    const { fetchedPage, page, tab } = this.state
    const { geo, history, currentDevice, socialActions, postId, postIds, social, device, user } = this.props
    const { allPosts } = social
    if (fetchedPage === page || page === -1) return
    const perPage = 30
    this._setState({
      fetchedPage: page,
      fetching: true
    }, () => {
      const commonArgs = {
        $limit: perPage,
        $skip: perPage * (page - 1),
        $sort: {
          createdAt: -1
        }
      }
      let args = Object.assign({}, commonArgs)
      if (currentDevice || geo) {
        if (this._showLocationBasedPosts()) {
          args = Object.assign({
            geo: {
              $near: {
                $geometry: geo || currentDevice.info.coords.geo,
                $maxDistance: KM * 1000
              }
            },
            status: 'published',
            expiresAt: { $lt: 14806304734411 }
          }, commonArgs, {
            $sort: {
              expiresAt: -1
            }
          })
        } else if (tab === 'user' && currentDevice) {
          args.deviceId = currentDevice._id
        }
      }
      if (postId) {
        args = {
          _id: postId
        }
      }
      if (postIds) {
        args = {
          _id: { $in: postIds.filter(pId => !pluck('_id', allPosts).includes(pId)).slice(0, 20) }
        }
      }
      socialActions.fetchPosts(args)
        .then(_res => {
          const st8 = {
            fetching: false
          }
          if (_res) {
            const res = _res.data || _res
            if (res.length === 0) {
              st8.page = -1
            // we did find posts at this is the logged in user's dasboard
            // and it's the first page
            } else if (this._isUserDashboard() && page === 1 && this.state.tab === 'location') {
              // use createDates to find personalized posts
              const createDates = map(dateVal, pluck('createdAt', res))
              if (createDates.length > 0) {
                const args = {
                  expiresAt: {
                    $gte: Math.min(...createDates)
                  },
                  $sort: {
                    expiresAt: -1
                  },
                  personalized: currentDevice.userId
                }
                socialActions.fetchPosts(args)
              }
            }
          }
          // my stuff
          if (this._isMyStuff()) {
            const args = Object.assign({}, commonArgs, {
              mine: user.info._id
            })
            socialActions.fetchPosts(args)
          }
          this._setState(st8)
          // make sure a single post belongs to this device
          if (postId && this._posts().length < 1) {
            history.push(this._socialTabPath())
          }
        })
    })
  }

  _isMyStuff () {
    const { tab } = this.state
    return tab === 'user' && this._showMyStuff()
  }

  _showMyStuff () {
    const { currentDevice, device, user } = this.props
    return (
      (this._isUserDashboard() && currentDevice && deviceIsMine(device, currentDevice._id)) ||
      (this._isSocialPage() && isLoggedIn(user))
    )
  }

  _socialTabPath () {
    const pieces = window.location.pathname.split('/')
    if (last(pieces) === 'social') {
      return window.location.pathname
    }
    return pieces.slice(0, -1).join('/')
  }

  _posts () {
    const { social, user, geo, currentDevice, postId, postIds } = this.props
    const { allPosts, favs } = social
    const { tab } = this.state
    const whitelistedDeviceIds = this._isUserDashboard() ? (favs || []).map(f => f.to._id) : []
    let ret = allPosts
      .filter(p => {
        if (postIds) return postIds.includes(p._id)
        // currentDevice will be here for the dashboard
        if (!currentDevice && !geo) return true
        // if (p.status === 'boosted') return true
        if (tab === 'all') {
          if (p.text) return true
          if (p.comments) return true
          return !/widget/.test(p.type)
        } else if (this._showLocationBasedPosts()) {
          const postGeo = path(['geo'], p)
          const deviceGeo = geo || path(['info', 'coords', 'geo'], currentDevice)
          let properDistance = false
          if (postGeo && deviceGeo) {
            if (postGeo.type === 'Point') {
              properDistance = latLonDistance(postGeo.coordinates[1], postGeo.coordinates[0], deviceGeo.coordinates[1], deviceGeo.coordinates[0], 'K') < KM
            } else if (postGeo.type === 'Polygon') {
              properDistance = booleanPointInPolygon(turf.point(deviceGeo.coordinates), turf.polygon(postGeo.coordinates))
            }
          }
          return (currentDevice && p.userId === currentDevice.userId) || properDistance
        } else {
          if (currentDevice && p.deviceId === currentDevice._id) return true
          if (this._showMyStuff() && p._mine) return true
        }
      })
      .filter(p => postId ? postId === p._id : true)
      .filter(p => isAdmin(user) || ['published', 'boosted'].includes(p.status))
      .sort((a, b) => {
        const prop = this._isMyStuff() ? 'updatedAt' : 'createdAt'
        return dateVal(b[prop]) - dateVal(a[prop])
      })

    const currentPostsDateRangeFilter = p => ret[ret.length - 1] && dateVal(p.createdAt) >= dateVal(ret[ret.length - 1].createdAt)
    // add fav devices posts ---------------------------- v this is a shortcut for making sure it's their own device
    if (this._isUserDashboard() && tab === 'location' && this._showMyStuff()) {
      const favPosts = allPosts
        .filter(p => whitelistedDeviceIds.includes(p.deviceId))
        .filter(currentPostsDateRangeFilter)
      ret = concat(ret, favPosts)
    }
    // add boosted posts
    if (tab !== 'user') {
      const boostedPosts = allPosts.filter(p => p.status === 'boosted')
      if (ret.length < 2) {
        return pipe(
          concat(boostedPosts),
          uniqBy(p => p._id),
          sort((a, b) => dateVal(b.createdAt) - dateVal(a.createdAt))
        )(ret)
      } else {
        return pipe(
          concat(boostedPosts.filter(currentPostsDateRangeFilter)),
          uniqBy(p => p._id),
          sort((a, b) => dateVal(b.createdAt) - dateVal(a.createdAt))
        )(ret)
      }
    }
    return ret
  }

  _isSocialPage () {
    return this.props.geo
  }

  render () {
    const { user, deviceActions, history, currentDevice, postId, postIds, geo } = this.props
    const { tab, fetching, page, isAtTop } = this.state
    // if (!currentDevice) return null
    let posts = <p className='no-posts'>{fetching ? 'Loading...' : 'No posts yet'}</p>
    const postsToShow = this._posts()
    if (postsToShow.length > 0) {
      posts = postsToShow
        .map(p => <DevicePopup
          id={p._id}
          currentDevice={{ _id: p.deviceId }}
          layerParam='social'
          post={p}
          key={p._id}
          onClick={theDevice => {
            deviceActions.setDashboardDevice(theDevice)
            history.push(getDeviceSharePath(theDevice) + '/social')
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
          }}
        />)
    }
    let tabs = [
      <a
        className={classNames('btn', { 'btn-primary': tab === 'all' && !postId })}
        onClick={() => this.setState({ tab: 'all' })}
        key='all'
      >
        Feed
      </a>,
      <a
        className={classNames('btn', { 'btn-primary': tab === 'location' && !postId })}
        onClick={() => this.setState({ tab: 'location' })}
        key='witin'
      >{this._isSocialPage() ? 'Local' : 'Community'}
      </a>
    ]
    if (isLoggedIn(user)) {
      tabs.push(
        <a
          className={classNames('btn', { 'btn-primary': tab === 'user' && !postId })}
          onClick={() => this.setState({ tab: 'user' })}
          key='stations'
        >{this._showMyStuff() ? 'My Activity' : 'Station only'}
        </a>
      )
    }
    if (!fetching && postId) {
      tabs = <Link className='btn btn-primary show-all' to={this._socialTabPath()}>Show All Posts</Link>
    }
    if (!currentDevice && !geo) {
      tabs = null
    }
    return (
      <div className={classNames('social-my-timeline', { 'at-top': isAtTop })}>
        <div className='controls'>
          {tabs}
        </div>
        <div className='posts'>
          {fetching ? <Loader /> : null}
          {posts}
        </div>
        <InView
          onChange={(inView, info) => {
            if (inView && !fetching && page !== -1 && !postId && !postIds) {
              this._setState({
                page: page + 1
              }, this._fetchPage.bind(this))
            }
          }}
        />
      </div>
    )
  }
}

export default bindAllActions(withRouter(MyTimeline))
MyTimeline.displayName = 'MyTimeline'
