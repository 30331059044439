import PropTypes from 'prop-types';
import { pluck, sum, map, pipe, reduce, reverse, sort, uniq } from 'ramda';
import { withRouter } from 'react-router'
import { Area, Line } from 'recharts'
import React, { PureComponent } from 'react';
import { isLoggedIn, isAdmin, pathsChanged, ucFirst } from '../../common/ambient';
import bindAllActions from '../../common/bindAllActions';
import { CommonGraph } from '../device';
import { MyTimeline } from '../social';

const PER_PAGE = 20

class AdminSocial extends PureComponent {
  static propTypes = {

  }
  state = {
    stats: [],
    page: 1,
    commentPostIds: []
  }
  componentDidMount() {
    this._fetchStats()
  }
  componentDidUpdate(prevProps) {
    const { user, history } = this.props
    if (pathsChanged(this.props, prevProps, [['user', 'userChecked']])) {
      if (!isLoggedIn(user) && !isAdmin(user)) {
        history.replace('/dashboard')
      }
      this._fetchStats()
    }
  }
  _fetchStats() {
    const { socialActions, user } = this.props
    const { page } = this.state
    if (!user.userChecked) return
    socialActions.getPost('stats')
      .then(stats => {
        const now = moment()
        this.setState({
          stats: pipe(
            sort((a, b) => {
              if (a.year === b.year) return a.day - b.day
              return a.year - b.year
            }),
            reduce((acc, obj) => {
              if (acc.length > 0) {
                // skip days after today
                if (obj.year === now.year() && obj.day > moment().dayOfYear()) {
                  return acc
                }
                // we're adding this day to the list
                obj.all = sum([obj.posts, obj.likes, obj.comments])
                acc.push(obj)
                // add 7 day avg
                if (acc.length > 7) {
                  obj.allAvg = sum(pluck('all', acc.slice(-7))) / 7
                  obj.postsAvg = sum(pluck('posts', acc.slice(-7))) / 7
                  obj.likesAvg = sum(pluck('likes', acc.slice(-7))) / 7
                  obj.commentsAvg = sum(pluck('comments', acc.slice(-7))) / 7
                }
                return acc
              }
              // skip days before we luanched social
              if (obj.posts < 1) {
                return []
              }
              return [obj]
            }, []),
            map(obj => {
              obj.dateutc = moment(`${obj.year}-${obj.day}`, 'YYYY-DDD').valueOf()
              return obj
            }),
            reverse
          )(stats)
        })
      })
    socialActions.fetchUserActions({
      type: 'comment',
      $limit: PER_PAGE,
      $skip: (page - 1) * PER_PAGE,
      $select: ['to._id'],
      $sort: {
        createdAt: -1
      }
    })
      .then(res => {
        this.setState({
          commentPostIds: uniq(res.data.map(ua => ua.to._id))
        })
      })
  }

  render() {
    const { stats, commentPostIds } = this.state
    return (
      <div className="user-admin-social">
        <header className="main">
          <h1>
            Social
          </h1>
        </header>
        <div className="page-body">
          {['all', 'posts', 'likes', 'comments'].map(dataKey =>
            <div
              key={dataKey}
            >
              <h3>{ucFirst(dataKey)}</h3>
            <CommonGraph
              height={200}
              graphData={stats}
              id={dataKey}
              composed
              tooltipProps={{
                itemStyle: { color: 'black' }
              }}
            >
              <Area
                dataKey={dataKey}
                type="monotone"
                stroke="#f5f5f5"
                strokeWidth={2}
                fill="#f5f5f5"
                dot={false}
                legendType="circle"
                isAnimationActive
                name={dataKey}
                connectNulls={true}
                fillOpacity="1"
              />
              <Line
                dataKey={`${dataKey}Avg`}
                type="monotone"
                fill="#2c9ce6"
                stroke="#2c9ce6"
                dot={false}
                legendType="circle"
                isAnimationActive
                name='7-day Avg'
                connectNulls
              />
            </CommonGraph></div>)
          }
        <div className='posts-wrap'>
          <div>
            <h3>Most Recent Posts</h3>
            <MyTimeline />
          </div>
          <div>
            <h3>Posts with Recent Comments</h3>
            {commentPostIds.length > 0 && <MyTimeline postIds={commentPostIds} />}
          </div>
        </div>
        </div>
      </div>
    );
  }
}

export default bindAllActions(withRouter(AdminSocial))
AdminSocial.displayName = 'AdminSocial'

AdminSocial.displayName = 'AdminSocial'

AdminSocial.displayName = 'AdminSocial'

AdminSocial.displayName = 'AdminSocial'

AdminSocial.displayName = 'AdminSocial'

AdminSocial.displayName = 'AdminSocial'

AdminSocial.displayName = 'AdminSocial'
